exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attendance-js": () => import("./../../../src/pages/attendance.js" /* webpackChunkName: "component---src-pages-attendance-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hrms-js": () => import("./../../../src/pages/hrms.js" /* webpackChunkName: "component---src-pages-hrms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboard-js": () => import("./../../../src/pages/onboard.js" /* webpackChunkName: "component---src-pages-onboard-js" */),
  "component---src-pages-payroll-js": () => import("./../../../src/pages/payroll.js" /* webpackChunkName: "component---src-pages-payroll-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-upskill-js": () => import("./../../../src/pages/upskill.js" /* webpackChunkName: "component---src-pages-upskill-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */)
}

